import { connect } from 'react-redux';
import cls from './Modules.module.scss';
import { useNavigate } from 'react-router-dom';
import { store } from '../../reduxStore/store';
import { setProcessModule, setCurrentTask, setShowMenu, setAllComponents } from '../../reduxStore/actions/flowActions';

import * as R from 'ramda';
import { isMobile } from 'react-device-detect';
import { app_max, APP_NAME, APPEND_TO_DATA, CLEAR_TO_SET_DATA } from '../../shared/utility';
import { take } from 'rxjs';
import { getAlComponentsByModuleId } from '../../services/module.service';
import { now } from 'moment';
import { Button } from '../common';
import { useState } from 'react';
import { getAllCompanyPositionsAsArray } from '../../services/company.service';

function ModulesMenu(props) {
	const { allModules, user, lastUpdates, components, blueprints, companyPositionsArray } = props;
	const [loading, setLoading] = useState(false);
	const [selected, setSelected] = useState();

	const navigate = useNavigate();
	return (
		<div className={cls.modulesMenu}>
			<div className={cls.listContainerFixed} style={{ flexDirection: isMobile ? 'column' : '', padding: isMobile ? '' : '0 12% 0 12%', overflow: 'auto' }}>
				{R.sortWith([R.ascend(R.prop('name'))])(allModules.filter(mod => ((mod?.options?.hide === false || (mod.options.hide === true && user.is_admin === true) || mod?.options?.hide === undefined)))).map((item, index) => {
					return (
						<Button
							clear
							className={cls.listButton}
							//loading={loading}
							style={{ backgroundColor: '#F6F4EC', margin: '12px 20px 12px 0px', paddingLeft: isMobile ? '50px' : '' }}
							key={index}
							clicked={() => {
								document.title = `${item.name} | ${APP_NAME}`;
								store.dispatch(setCurrentTask(undefined));
								store.dispatch(
									setProcessModule({
										...item.procedure,
										stepIndex: 0,
										module_id: item.id,
										module: item,
									})
								);

								if (companyPositionsArray.length ===0){
									getAllCompanyPositionsAsArray().pipe(take(1)).subscribe({});
								}
								
								if (components.filter(co => co?.module_id === item.id && co?.component_fields?.length == 0).length > 0 || components.filter(co => co?.module_id === item.id).length === 0) {
									setLoading(true);
									const module = allModules.find(m => m.id === item.id);
									setSelected(module.name);
									lastUpdates.componentsByModule[module.name] = 0;
									getAlComponentsByModuleId(module, APPEND_TO_DATA, app_max, 1)
										.pipe(take(1))
										.subscribe({
											next: data => {
												const components_data = data?.data ? data?.data : data;

												store.dispatch(setAllComponents(R.sortBy(R.prop('created_date'), [...components.filter(c => c.module_id !== module.id), ...components_data]), CLEAR_TO_SET_DATA, blueprints.map(bp => bp.fields).flat(1)));

												lastUpdates.componentsByModule[module.name] = now();
												module.lastUpdate = now();
												module.count = data?.total ?? 0;

												if (module?.options?.supporting?.length > 0) {
													module?.options?.supporting?.filter((support, index) => {
														const smodule = allModules.find(m => m.id === support);
														setSelected(smodule.name);
														getAlComponentsByModuleId({ id: support, name: `${allModules?.find(mod => mod.id === support)?.name}${index}` ?? `${module?.name}${index}` }, APPEND_TO_DATA, app_max, 1)
															.pipe(take(1))
															.subscribe({
																next: (data) => {

																	if (module?.options?.supporting?.length == index + 1) {
																		setLoading(false);
																		store.dispatch(setShowMenu(false));
																		navigate('/begin-module');
																	}

																},
																error: () => setLoading(false)
															})
													});
													// setLoading(false);
													// store.dispatch(setShowMenu(false));
													// navigate('/begin-module');
												} else {
													setLoading(false);
													store.dispatch(setShowMenu(false));
													navigate('/begin-module');
												}

											},
											complete: () => { },
											error: () => setLoading(false),
										});

								} else {
									store.dispatch(setShowMenu(false));
									navigate('/begin-module');
								}
							}}
						>
							{item.name}
						</Button>
					);
				})}
				{loading && <Button clear loading={loading} style={{ backgroundColor: '#F6F4EC' }} message={`Fetching ${selected}... `} />}
			</div>

		</div>
	);
}
const mapStateToProps = store => {
	const { allModules, lastUpdates, components, blueprints, companyPositionsArray } = store.flowReducer;
	const { user } = store.sessionReducer;
	return { allModules, user, lastUpdates, components, blueprints, companyPositionsArray };
};

export default connect(mapStateToProps)(ModulesMenu);
